import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IProduto, IProdutoCaracteristica } from '@/models/Entidades/Cadastros/Produtos/IProduto';
import { IItemGenerico } from '@/core/models/IItemGenerico';
import { IRetornoArquivo } from '@/core/models/IRetornoArquivo';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ICaracteristica } from '@/models/Entidades/Cadastros/Produtos/ICaracteristica';
import { ETipoCaracteristica } from '@/models/Enumeradores/Cadastros/Produtos/ETipoCaracteristica';
import { IDTOProduto } from '@/models/DTO/Cadastros/Produtos/IDTOProduto';
import { IParametrosConsultaProdutoDefinicao } from '@/models/ParametrosRequisicao/Cadastros/Produtos/IParametrosConsultaProdutoDefinicao';
import { IDTOProdutoDefinicaoIdentificacao } from '@/models/DTO/Cadastros/Produtos/IDTOProdutoDefinicaoIdentificacao';
import { IDTOProdutoDefinicao } from '@/models/DTO/Cadastros/Produtos/IDTOProdutoDefinicao';
import { ETipoProduto } from '@/models/Enumeradores/Cadastros/Produtos/ETipoProduto';
import { IEstoque } from '@/models/Entidades/Cadastros/Estoques/IEstoque';
import { IDTOProdutoCaracteristica } from '@/models/DTO/Cadastros/Produtos/IDTOProdutoCaracteristica';
import { IDTOProdutoDefinicaoMovimento } from '@/models/DTO/Cadastros/Produtos/IDTOProdutoDefinicaoMovimento';

/**
 * Serviço de Produtos
 * Fornece todas regras de negócios e lógicas relacionado a Produtos.
 */

class ServicoProduto implements IServicoBase<IProduto> {
    endPoint = 'produtos';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterListaTipoProduto(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/tipos`);
      return result.data;
    }

    public async obterListaTipoRastreabilidade(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/tipos-rastreabilidades`);
      return result.data;
    }

    public async obterListaTipoLancamentoRastreabilidade(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/tipos-lancamento-rastreabilidades`);
      return result.data;
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async obterPropriedadesConsultaDefinicoes(): Promise<IPropriedadeConsulta[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/definicoes/propriedades-consulta`);
      return result.data;
    }

    public async obterDadosIdentificacaoDefinicao(codigoDefinicao:number): Promise<IDTOProdutoDefinicaoIdentificacao> {
      const result: any = await this.apiERP.get(`${this.endPoint}/definicoes/${codigoDefinicao}/identificacao`);
      return result.data;
    }

    public async obterDadosIdentificacaoDefinicaoPorCodigoBarrasOuCodigoFornecedor(empresa:number, codigoBarras:string, fornecedor:number, codigoFornecedor: string): Promise<IDTOProdutoDefinicaoIdentificacao> {
      let parametrosAdicionais = '';
      if (UtilitarioGeral.valorValido(codigoBarras)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `codigoBarras=${codigoBarras}`;
      }

      if (UtilitarioGeral.validaCodigo(fornecedor)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `fornecedor=${fornecedor}`;
      }

      if (UtilitarioGeral.valorValido(codigoFornecedor)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `codigoFornecedor=${codigoFornecedor}`;
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/definicoes/empresa/${empresa}/identificacao${parametrosAdicionais}`);
      return result.data;
    }

    public async obterDadosDetalhesDefinicao(codigoDefinicao:number): Promise<IDTOProdutoDefinicao> {
      const result: any = await this.apiERP.get(`${this.endPoint}/definicoes/${codigoDefinicao}/detalhes`);
      return result.data;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
    }

    public async buscaAvancadaDefinicoes(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], parametrosConsultaProdutoDefinicao?:IParametrosConsultaProdutoDefinicao): Promise<IListaPaginada> {
      const parametrosEmpresas = this.apiERP.obterParametroEmpresas(parametrosConsulta.empresas);
      let parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsulta(parametrosConsulta, filtros);
      if (parametrosConsultaProdutoDefinicao !== undefined) {
        if (UtilitarioGeral.validaBoleano(parametrosConsultaProdutoDefinicao.apenasAtivos)) {
          parametrosAdicionais += `&apenasAtivos=${parametrosConsultaProdutoDefinicao.apenasAtivos}`;
        }
        if (UtilitarioGeral.valorValido(parametrosConsultaProdutoDefinicao.ausentesTabelaPreco)) {
          parametrosAdicionais += `&ausentesTabelaPreco=${parametrosConsultaProdutoDefinicao.ausentesTabelaPreco}`;
        }
        if (UtilitarioGeral.valorValido(parametrosConsultaProdutoDefinicao.presentesTabelaPreco)) {
          parametrosAdicionais += `&presentesTabelaPreco=${parametrosConsultaProdutoDefinicao.presentesTabelaPreco}`;
        }
        if (UtilitarioGeral.valorValido(parametrosConsultaProdutoDefinicao.ausentesBalancoEstoque)) {
          parametrosAdicionais += `&ausentesBalancoEstoque=${parametrosConsultaProdutoDefinicao.ausentesBalancoEstoque}`;
        }
        if (UtilitarioGeral.valorValido(parametrosConsultaProdutoDefinicao.presentesBalancoEstoque)) {
          parametrosAdicionais += `&presentesBalancoEstoque=${parametrosConsultaProdutoDefinicao.presentesBalancoEstoque}`;
        }
      }

      const result: any = await this.apiERP.get(`${this.endPoint}/definicoes${parametrosEmpresas}${parametrosAdicionais}`);
      const listaPaginada = {} as IListaPaginada;
      listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
      listaPaginada.dados = result.data;
      return listaPaginada;
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: IDTOProduto): string {
      return objeto.descricao;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida, apresentarCodigoInterno?:boolean): Promise<IItemConsultaRapida[]> {
      let parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      if (UtilitarioGeral.validaBoleano(apresentarCodigoInterno)) {
        parametrosAdicionais += `&apresentarCodigoInterno=${apresentarCodigoInterno}`;
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async consultaRapidaDefinicoes(parametrosConsultaRapida: IParametrosConsultaRapida, apresentarCodigoInterno?:boolean): Promise<IItemConsultaRapida[]> {
      let parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      if (UtilitarioGeral.validaBoleano(apresentarCodigoInterno)) {
        parametrosAdicionais += `&apresentarCodigoInterno=${apresentarCodigoInterno}`;
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/definicoes/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async consultaRapidaDefinicoesMovimentos(parametrosConsultaRapida: IParametrosConsultaRapida, apresentarCodigoInterno?:boolean, filtrarVinculoEstoqueEntrada?:boolean, filtrarVinculoEstoqueSaida?:boolean): Promise<IDTOProdutoDefinicaoMovimento[]> {
      let parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      if (UtilitarioGeral.validaBoleano(apresentarCodigoInterno)) {
        parametrosAdicionais += `&apresentarCodigoInterno=${apresentarCodigoInterno}`;
      }
      if (UtilitarioGeral.validaBoleano(filtrarVinculoEstoqueEntrada)) {
        parametrosAdicionais += `&filtrarVinculoEstoqueEntrada=${filtrarVinculoEstoqueEntrada}`;
      }

      if (UtilitarioGeral.validaBoleano(filtrarVinculoEstoqueSaida)) {
        parametrosAdicionais += `&filtrarVinculoEstoqueSaida=${filtrarVinculoEstoqueSaida}`;
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/definicoes/movimentos/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async incluir(objeto: IProduto): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, objeto);
      return result.data;
    }

    public async alterar(objeto: IProduto): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${objeto.codigo}`, objeto);
      return result.data;
    }

    public async obter(codigo: number, empresa: number): Promise<IProduto> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async obterCodigoDefinicaoPrincipal(codigo: number): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/definicoes/codigo/principal`);
      return result.data;
    }

    public async obterCaracteristicasDefinicaoProduto(produtoDefinicao: number, empresa: number): Promise<IDTOProdutoCaracteristica[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/produto-definicao/caracteristicas/${produtoDefinicao}/empresa/${empresa}`);
      return result.data;
    }

    public async obterTipoProdutoDefinicao(codigoDefinicao: number, empresa: number): Promise<ETipoProduto> {
      const result: any = await this.apiERP.get(`${this.endPoint}/tipo-produto-definicao/${codigoDefinicao}/empresa/${empresa}`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
      return result.data;
    }

    public async obterCodigosNcmsVinculadoProdutos(empresas:number[]): Promise<number[]> {
      let parametrosAdicionais = '';
      empresas.forEach((codigoEmpresa) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `empresas=${codigoEmpresa}`;
      });

      const result: any = await this.apiERP.get(`${this.endPoint}/ncms${parametrosAdicionais}`);
      return result.data;
    }

    public async enviarImagens(imagens: any[], codigoEmpresa:number): Promise<IRetornoArquivo[]> {
      const data = new FormData();

      for (let i = 0; i < imagens.length; (i += 1)) {
        data.append('imagens', imagens[i]);
      }
      const result: any = await this.apiERP.upload(`${this.endPoint}/imagens/empresa/${codigoEmpresa}`, data);
      return result.data;
    }

    public async removerImagem(codigoProdutoImagem: number, imagem: string): Promise<IRetornoArquivo> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/imagens/${codigoProdutoImagem}`, imagem);
      return result.data;
    }

    public async consultarMarcadores(marcardor: string): Promise<string[]> {
      let parametrosAdicionais = '';
      parametrosAdicionais += `?Marcador=${marcardor}`;

      const result: any = await this.apiERP.get(`${this.endPoint}/marcadores${parametrosAdicionais}`);
      return result.data;
    }

    public montaDescricaoVariacaoProduto(produtoCaracteristicas: IProdutoCaracteristica[], caracteristicas: ICaracteristica[]):string {
      let descricaoVariacao = '';
      if (UtilitarioGeral.validaLista(produtoCaracteristicas) && UtilitarioGeral.validaLista(caracteristicas)) {
        const caracteristicasComVariacao = produtoCaracteristicas.filter((c) => c.variacao === true);
        if (UtilitarioGeral.validaLista(caracteristicasComVariacao)) {
          caracteristicasComVariacao.forEach((produtoCaracteristica) => {
            const dadosCaracteristica = caracteristicas.find((c) => c.codigo === produtoCaracteristica.codigoCaracteristica);
            if (dadosCaracteristica !== undefined) {
              if (dadosCaracteristica.tipo === ETipoCaracteristica.TextoLivre
                || dadosCaracteristica.tipo === ETipoCaracteristica.Decimal
                || dadosCaracteristica.tipo === ETipoCaracteristica.Numero) {
                descricaoVariacao += ` ${dadosCaracteristica.descricao}: ${produtoCaracteristica.caracteristicaPersonalizada}`;
              } else if (dadosCaracteristica.tipo === ETipoCaracteristica.ListaSimples
                || dadosCaracteristica.tipo === ETipoCaracteristica.Cor) {
                const dadosItemCaracteristica = dadosCaracteristica.itens.find((c) => c.codigo === produtoCaracteristica.codigoCaracteristicaItem);
                if (dadosItemCaracteristica !== undefined) {
                  descricaoVariacao += ` ${dadosCaracteristica.descricao}: ${dadosItemCaracteristica.valor}`;
                }
              } else if (dadosCaracteristica.tipo === ETipoCaracteristica.ListaSubItens) {
                const dadosItemCaracteristica = dadosCaracteristica.itens.find((c) => c.codigo === produtoCaracteristica.codigoCaracteristicaItem);
                if (dadosItemCaracteristica !== undefined) {
                  const dadosSubItemCaracteristica = dadosItemCaracteristica.itens.find((c) => c.codigo === produtoCaracteristica.codigoCaracteristicaSubItem);
                  if (dadosSubItemCaracteristica !== undefined) {
                    descricaoVariacao += ` ${dadosCaracteristica.descricao}: ${dadosSubItemCaracteristica.valor}`;
                  }
                }
              }
            }
          });
        }
      }
      return descricaoVariacao;
    }

    public async PreencherEstoquesProduto(definicaoProduto: number, empresa: number, estoques: IEstoque[]): Promise<IEstoque[]> {
      const tipo = await this.obterTipoProdutoDefinicao(definicaoProduto, empresa);
      const estoquesDisponiveis = [] as IEstoque[];
      estoques.forEach((e) => {
        e.tiposProdutos.forEach((t) => {
          if (t.tipoProduto === tipo) {
            estoquesDisponiveis.push(e);
          }
        });
      });

      return estoquesDisponiveis;
    }
}
export default ServicoProduto;
