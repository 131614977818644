
import { defineComponent, ref } from 'vue';
import TelaPadraoCrud from '@/core/components/TelaPadraoCrud.vue';
import { useCrudBase } from '@/core/composables/CrudBase';
import TabelaPrecoModal from '@/views/Cadastros/Precificacao/TabelasPreco/TabelaPrecoModal.vue';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import ServicoTabelaPreco from '@/servicos/Cadastros/Precificacao/ServicoTabelaPreco';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';

export default defineComponent({
  name: 'TabelaPreco',
  components: {
    TelaPadraoCrud,
    TabelaPrecoModal,
  },
  setup() {
    const {
      crudBase, preencherEstrutura, exibirModal, sincronizaTelaCrud,
    } = useCrudBase();
    const servicoTabelaPreco = new ServicoTabelaPreco();
    const refCrudTabelaPreco = ref<InstanceType<typeof TelaPadraoCrud>>();

    crudBase.estrutura = preencherEstrutura();
    crudBase.estrutura.botaoPrincipalAcao = 'Nova';
    crudBase.estrutura.mensagemExclusao = 'Você confirma a exclusão da tabela de preço:';
    crudBase.estrutura.colunasGrade = [
      {
        title: 'Código', dataIndex: 'codigo', key: 'CodigoTabelaPreco', position: 0, visible: false, ordering: true,
      },
      {
        title: 'Descrição', dataIndex: 'descricao', key: 'DescricaoTabelaPreco', position: 1, visible: true, ordering: true, fixed: 'left', align: 'left', customRenderRow: ECustomRenderRow.LinkAcao,
      },
      {
        title: 'Conceito', dataIndex: 'conceitoPrecificacaoDescricao', key: 'ConceitoPrecificacaoDescricao', width: 220, position: 3, visible: true, align: 'left',
      },
      {
        title: 'Novos itens', dataIndex: 'formaInclusaoItensDescricao', key: 'FormaInclusaoItensDescricao', width: 220, position: 3, visible: true, align: 'left',
      },
      {
        title: 'Status', dataIndex: 'statusDescricao', key: 'StatusTabelaPreco', position: 4, visible: true, align: 'left', width: 100,
      },
      {
        title: 'Ações', key: 'acoes', position: 5, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
      },
    ];
    crudBase.estrutura.ordenacaoPadraoGrade.push({ identificador: 'Codigo', ordem: 'DESC' } as IOrdenacao);

    async function sincronizarRegistro(acao: EPermissaoDados, codigoRegistro: number) {
      if (refCrudTabelaPreco.value !== undefined) {
        await refCrudTabelaPreco.value.sincronizarRegistro(acao, codigoRegistro);
      }
    }
    return {
      crudBase,
      servicoTabelaPreco,
      refCrudTabelaPreco,
      exibirModal,
      sincronizaTelaCrud,
      sincronizarRegistro,
    };
  },
});
